import { Button, TextField, Typography, Alert } from "@mui/material";
import Box from "@mui/joy/Box";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { useUpdateBatteryMutation } from "../../shared/api/api";

const ReplaceBattery = () => {
    const location = useLocation(); // Отримуємо параметри з state
    const { batteryNumber, position, namePost } = location.state || {}; // Отримуємо номер батареї та позицію

    // Стан для зберігання значення нової батареї
    const [newBattery, setNewBattery] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState(null); // Стан для повідомлення про успіх
    const [errorMessage, setErrorMessage] = React.useState(null); // Стан для повідомлення про помилку

    // Мутація для відправки запиту на оновлення батареї
    const [updateBattery, { isLoading }] = useUpdateBatteryMutation();

    // Обробник кліку на кнопку Submit
    const handleSubmit = async () => {
        try {
            await updateBattery({
                postName: position, // Використовуємо позицію як ім'я поста
                batteryOld: batteryNumber, // Поточне значення батареї
                batteryNew: parseInt(newBattery) // Нове значення батареї
            });
            setSuccessMessage("Battery updated successfully!"); // Встановлюємо повідомлення про успіх
            setErrorMessage(null); // Очищуємо попередні помилки, якщо були
        } catch (error) {
            console.error('Error updating battery:', error);
            setErrorMessage("Failed to update battery. Please try again."); // Встановлюємо повідомлення про помилку
            setSuccessMessage(null); // Очищуємо повідомлення про успіх, якщо було
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 2,
                    mb: 8,
                    textDecoration: 'underline'
                }}>
                <Typography variant="h6" component="h1">
                    Battery replacement - {namePost} {/* Виводимо номер батареї або N/A */}
                </Typography>
            </Box>

            {/* Повідомлення про успіх або помилку */}
            {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}
            {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}

            {/* Поле для введення */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px', // Відстань між елементами
                    width: '100%', // Повна ширина екрана
                    px: 2 // Внутрішні відступи по горизонталі
                }}
            >
                <TextField
                    label="New Battery Number"
                    variant="outlined"
                    type="number" // Поле приймає лише цифри
                    fullWidth
                    value={newBattery}
                    onChange={(e) => setNewBattery(e.target.value)} // Оновлення стану при введенні
                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Дозволяє тільки цифри
                    sx={{
                        height: '70px', // Висота поля
                        '& .MuiInputBase-root': {
                            height: '70px', // Висота текстового поля
                        }
                    }}
                />

                {/* Кнопка */}
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleSubmit}
                    disabled={isLoading || !newBattery} // Блокування кнопки, якщо поле порожнє або запит в процесі
                    sx={{
                        height: '70px', // Висота кнопки
                        mt: 2, // Відступ зверху
                        backgroundColor: '#32a852', // Зелений фон
                        '&:hover': {
                            backgroundColor: '#28a745', // Зміна кольору при ховері
                        }
                    }}
                >
                    {isLoading ? 'Submitting...' : 'Submit'}
                </Button>
            </Box>
        </>
    );
};

export default ReplaceBattery;
