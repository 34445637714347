import {Box} from "@mui/material";
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();

  return(
      <Box
          sx={{
            height: '50px',
            backgroundColor: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.common.white
          }}
      >
        <Box>© 2024 Kalyna</Box>
      </Box>
  )
}

export default Footer