import {Routes, Route, BrowserRouter} from "react-router-dom";
import Layout from "../../pages/common/Layout";
import NoMatch from "../../pages/common/NoMatch";
import Electricity from "../../pages/electricity/Electricity";
import Battery from "../../pages/battery/Battery";
import StorageBattery from "../../pages/storage/StorageBattery";
import ReplaceBattery from "../../pages/battery/ReplaceBattery";
import BatteryChange from "../../pages/battery/BatteryChange";
import Trends from "../../pages/trends/Trends";


const RootNavigator = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Electricity/>}/>
                    <Route path="battery" element={<Battery/>}/>
                    <Route path="trends" element={<Trends />}/>
                    <Route path="battery-change" element={<BatteryChange />}/>
                    <Route path="battery/dacha" element={<ReplaceBattery/>}/>
                    <Route path="battery/dementor" element={<ReplaceBattery/>}/>
                    <Route path="battery/torec" element={<ReplaceBattery/>}/>
                    <Route path="battery/luka1" element={<ReplaceBattery/>}/>
                    <Route path="battery/luka2" element={<ReplaceBattery/>}/>
                    <Route path="storage" element={<StorageBattery/>}/>
                    <Route path="*" element={<NoMatch/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default RootNavigator;
