import {Outlet} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import {Box} from "@mui/material";

const Layout = () => {
    return (
        <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <Header />

            <Box sx={{ flexGrow: 1, mr:1, ml:1, mt: 2 }}>
                <Outlet />
            </Box>

            <Footer />
        </Box>
    );
}

export default Layout