import Menu from "./Menu";
import {Box} from "@mui/material";

const Header = () => {
    return (
        <Box>
            <Menu />
        </Box>
    )
}

export default Header