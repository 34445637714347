import React, { useState } from 'react';
import { useGetElectricityFilterDataQuery } from '../../shared/api/api';
import { TextField, Button, Box, Typography, Checkbox, FormControlLabel, FormGroup, Grid2 } from '@mui/material';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { format } from 'date-fns';

// Функція для форматування дати у формат 'yyyy-MM-ddTHH:mm'
const formatDateForInput = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2);
    const day = (`0${d.getDate()}`).slice(-2);
    const hours = (`0${d.getHours()}`).slice(-2);
    const minutes = (`0${d.getMinutes()}`).slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// Функція для форматування дати на осі X
const formatXAxisDate = (date) => format(new Date(date), 'dd.MM.yy HH:mm');

const Trends = () => {
    // Отримуємо поточну дату і час
    const now = new Date();
    // Встановлюємо `startDate` на 24 години раніше від поточної дати
    const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000).toISOString();
    const currentDate = now.toISOString();

    // Використовуємо ці значення як початкові стани для `startDate` та `endDate`
    const [startDate, setStartDate] = useState(oneDayAgo);
    const [endDate, setEndDate] = useState(currentDate);

    const { data: filteredData, refetch } = useGetElectricityFilterDataQuery({
        startDate,
        endDate,
    });

    const [visiblePosts, setVisiblePosts] = useState({}); // Стан для відображення постів
    const [showCurrent, setShowCurrent] = useState(false); // Відповідає за відображення струму

    // Масив кольорів для постів
    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088fe']; // Можна додати більше кольорів
    const getColorForPost = (index) => colors[index % colors.length]; // Повертає колір за індексом

    // Форматуємо дані з перетворенням timestamp на Date
    const formattedData = filteredData?.map((item) => ({
        ...item,
        timestamp: new Date(item.timestamp),
    })) || [];

    // Фільтрація графіків на основі чекбоксів
    const handleCheckboxChange = (event) => {
        setVisiblePosts({
            ...visiblePosts,
            [event.target.name]: event.target.checked
        });
    };

    // Обробка зміни чекбокса для показу струму
    const handleCurrentToggle = () => {
        setShowCurrent(!showCurrent);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6" align="center" gutterBottom>
                Фільтрація даних за періодом та відображення трендів напруги та струму
            </Typography>

            <Grid2 container spacing={2} justifyContent="center" alignItems="center" sx={{ marginBottom: 2 }}>
                <Grid2>
                    <TextField
                        label="Початкова дата"
                        type="datetime-local"
                        value={formatDateForInput(startDate)} // Форматована дата для input
                        onChange={(e) => setStartDate(new Date(e.target.value).toISOString())}
                    />
                </Grid2>
                <Grid2>
                    <TextField
                        label="Кінцева дата"
                        type="datetime-local"
                        value={formatDateForInput(endDate)} // Форматована дата для input
                        onChange={(e) => setEndDate(new Date(e.target.value).toISOString())}
                    />
                </Grid2>
                <Grid2>
                    <Button variant="contained" color="primary" onClick={() => refetch()}>
                        Фільтрувати
                    </Button>
                </Grid2>
            </Grid2>

            {/* Чекбокси для контролю видимості графіків */}
            <FormGroup row>
                {Array.from(new Set(formattedData.map(item => item.post_name))).map((postName, index) => (
                    <FormControlLabel
                        key={postName}
                        control={
                            <Checkbox
                                checked={visiblePosts[postName] || false}
                                onChange={handleCheckboxChange}
                                name={postName}
                            />
                        }
                        label={postName}
                    />
                ))}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showCurrent}
                            onChange={handleCurrentToggle}
                            name="showCurrent"
                        />
                    }
                    label="Показати струм"
                />
            </FormGroup>

            {console.log(formattedData)}

            {/* Виведення графіків */}
            {formattedData.length > 0 ? (
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart
                        data={formattedData}
                        margin={{ top: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="timestamp"
                            tickFormatter={(tick) => formatXAxisDate(tick)} // Форматування дати на осі X у форматі dd.MM.yy HH:mm
                            tick={{ fontSize: 10, angle: -45 }} // Повертаємо підписи на 45 градусів та зменшуємо шрифт
                            height={70}
                            padding={{ left: 30, right: 30 }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />

                        {/* Динамічно відображаємо лінії для кожного поста */}
                        {Object.keys(visiblePosts).map((postName, index) => (
                            visiblePosts[postName] && (
                                <React.Fragment key={postName}>
                                    {/* Лінія для напруги */}
                                    <Line
                                        key={`${postName}-voltage`}
                                        type="monotone"
                                        dataKey="voltage"
                                        name={`${postName} - Напруга`}
                                        stroke={getColorForPost(index)} // Призначаємо колір для кожного поста
                                        data={formattedData.filter(item => item.post_name === postName)}
                                        dot={false}
                                    />
                                    {showCurrent && (
                                        <Line
                                            key={`${postName}-current`}
                                            type="monotone"
                                            dataKey="current"
                                            name={`${postName} - Струм`}
                                            stroke={getColorForPost(index)} // Призначаємо колір для струму поста
                                            strokeDasharray="3 3" // Додаємо штриховану лінію для струму
                                            data={formattedData.filter(item => item.post_name === postName)}
                                            dot={false}
                                        />
                                    )}
                                </React.Fragment>
                            )
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            ) : (
                <Typography variant="body1" align="center" color="textSecondary">
                    Дані відсутні для вибраного періоду
                </Typography>
            )}
        </Box>
    );
};

export default Trends;
