import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: 'http://146.120.248.94:5000/api',
        // baseUrl: 'http://localhost:5000/api',
    }),
    tagTypes: ['StorageBattery'], // Додаємо тип тега тут
    endpoints: (builder) => ({
        getElectricityData: builder.query({
            query: () => '/electricity',
        }),
        getElectricityFilterData: builder.query({
            query: ({ startDate, endDate }) => {
                const params = new URLSearchParams();
                if (startDate) params.append('startDate', startDate);
                if (endDate) params.append('endDate', endDate);
                return {
                    url: `/electricity/filter?${params.toString()}`,
                    method: 'GET',
                };
            },
        }),
        getRelayData: builder.query({
            query: () => '/relay',
        }),
        updateRelayStatus: builder.mutation({
            query: (body) => ({
                url: '/relay/update',
                method: 'POST',
                body,
            }),
        }),
        getStorageBattery: builder.query({
            query: () => '/battery/storage',
            providesTags: ['StorageBattery'], // Додаємо тег для запиту
        }),
        updateBattery: builder.mutation({
            query: (body) => ({
                url: '/battery/update',
                method: 'POST',
                body,
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(api.util.invalidateTags(['StorageBattery'])); // Інвалідуємо тег після оновлення
                } catch (error) {
                    console.error('Помилка під час оновлення батареї:', error);
                }
            },
        }),
    }),
});

export const {
    useGetElectricityDataQuery,
    useGetElectricityFilterDataQuery,
    useGetRelayDataQuery,
    useUpdateRelayStatusMutation,
    useGetStorageBatteryQuery,
    useUpdateBatteryMutation,
} = api;
