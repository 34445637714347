import './App.css';
import RootNavigator from "./app/router/RootNavigator";
import {StoreProvider} from "./app/providers/storeProvider/ui/StoreProvider";


const App = () => {
    return (
            <StoreProvider>
                <RootNavigator/>
            </StoreProvider>
    )
}


export default App;
